 .add-cell {
   position:relative;
   opacity:0.75;
   transition:opacity 0.5s ease-in;
   margin: 10px 0;
 }
  
 .add-cell:hover {
   opacity:1.0;
 }

 .add-cell .divider {
   position: absolute;
   top: 50%;
   bottom: 50%;
   left:2.5%;
   right:2.5%;
   border-bottom: 1px solid gray;
   width:90%;
   z-index:-1;
 }

 .add-cell .add-buttons {
   display:flex;
   justify-content:center; 
 }


 .add-cell .add-buttons button {
   margin:0 40px;
 }


