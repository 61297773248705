.cell-list-item {
  position: relative;
  margin:40px 10px; 
}

.action-bar-wrapper {
  height:30px;
  width: 100%;
  background-color: #37414d;
}
